import { useForm } from 'react-hook-form'

import { Navigation, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import useEmail from './useEmail'

const Email = ({ pageContext }) => {
  const {
    leadData: { email }
  } = useLead()

  const initialValues = {
    email: email ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, isButtonLoading } = useEmail()

  return (
    <ProposalLayout pageContext={pageContext}>
      <>
        <h2 className="proposal-title --mt">{'Qual o seu e-mail?'}</h2>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Input.Email
            label="E-mail"
            id="email"
            data-cy="cy-input-email"
            name="email"
            autoComplete="on"
            control={control}
          />

          <Navigation
            submit
            nextStep
            isNextButtonDisabled={!isValid}
            isNextStepButtonLoading={isButtonLoading}
            nextButtonId={'basic-data-next-btn'}
            dataCy="cy-btn-next"
            nextButtonText="Continuar simulação"
          />
        </form>
      </>
    </ProposalLayout>
  )
}

export default Email
