import { useForm } from 'react-hook-form'

import { Navigation, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import usePhone from './usePhone'

const Phone = ({ pageContext }) => {
  const {
    leadData: { phone }
  } = useLead()

  const initialValues = {
    phone: phone ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, isButtonLoading } = usePhone()

  return (
    <ProposalLayout pageContext={pageContext}>
      <>
        <h2 className="proposal-title --mt">{'E o número do seu celular?'}</h2>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Input.Cellphone
            label="Celular"
            id="phone"
            data-testid="phone"
            data-cy="cy-input-phone"
            name="phone"
            mask="phone"
            autoComplete="on"
            control={control}
          />

          <Navigation
            submit
            nextStep
            isNextStepButtonLoading={isButtonLoading}
            isNextButtonDisabled={!isValid}
            nextButtonId={'basic-data-next-btn'}
            dataCy="cy-btn-next"
            nextButtonText="Continuar simulação"
          />
        </form>
      </>
    </ProposalLayout>
  )
}

export default Phone
