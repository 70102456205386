import { IdsIcon, IdsLoading, IdsActionButton } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import classnames from 'classnames'
import { segment } from 'utils'

import './directional-button.scss'

const DirectionalButton = ({
  id,
  callback,
  currentProposal: proposal,
  isButtonLoading,
  setButtonLoading,
  disabled,
  className,
  buttonText='Continuar',
  ariaLabel='botão continuar',
  dataTestid = '',
  isHero = false,
  offerIdx = null,
  hasEditScreen = false,
  coldOffer
}) => {
  const { setProduct } = useProposal()
  const productCode = proposal?.productCode

  const classNames = classnames('directional-button', {
    [className]: className
  })

  const handleClick = () => {
    setButtonLoading?.(true)

    segment.buttonClick({
      currentButton: {
        innerText: buttonText,
        dataset: { id }
      },
      payload: {
        productName: productCode,
        type: isHero ? 'Hero' : 'Carrossel',
        order: offerIdx
      }
    })

    analytics.track('buttonClick', {
      currentPage: 'vitrine-shopping',
      detail: 'click-intencao-vitrine',
      eventName: 'conversion',
      customLayer: {
        description: isHero ? 'hero' : `carrossel-${offerIdx + 1}`
      }
    })
    callback({
      proposal,
      setButtonLoading,
      setProduct,
      hasEditScreen,
      coldOffer
    })
  }

  return (
    <IdsActionButton
      id={id}
      data-testid={dataTestid}
      aria-label={ariaLabel}
      disabled={isButtonLoading || disabled}
      onClick={handleClick}
      className={classNames}
      full
    >
      <If
        condition={isButtonLoading}
        renderIf={
          <IdsLoading
            className="wrapper-sendeer__submit-loading"
            aria-live="polite"
            aria-label="aguarde, estamos enviando seus dados..."
            size="XS"
            show={true}
          />
        }
        renderElse={
          <>
            {buttonText}
            <IdsIcon>seta_direita</IdsIcon>
          </>
        }
      />
    </IdsActionButton>
  )
}

export default DirectionalButton
