import { IdsIcon } from '@ids/react'
import ProposalLayout from 'layouts/Proposal'

import './agreement.scss'
import Form from './components/Form'
import useAgreement from './hooks'

const Agreement = ({ pageContext }) => {
  useAgreement()

  return (
    <ProposalLayout pageContext={pageContext}>
      <div className="container agreement-form">
        <h2 className="agreement-form__title">
          Você autoriza o Itaú a consultar os dados do seu benefício INSS?
        </h2>
        <div className="agreement-form__security">
          <IdsIcon size="L">trancado</IdsIcon>
          <p className="agreement-form__security-text">
            Seus dados protegidos com o Itaú
          </p>
        </div>
      </div>
      <Form />
    </ProposalLayout>
  )
}

export default Agreement
