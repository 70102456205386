import {
  IdsList,
  IdsLi,
  IdsLiText,
  IdsLiSubText,
  IdsLiDescription,
  IdsLiSubDescription
} from '@ids/react'
import { Shimmer, If } from 'components'
import { formatters } from 'utils'

const FGTSList = ({ proposal, isEditingLoading }) => (
  <IdsList>
    <IdsLi>
      <IdsLiText>Saques antecipados</IdsLiText>
      <Shimmer isVisible={isEditingLoading}>
        <IdsLiDescription>
          {proposal?.installmentsNumber} saque
          {proposal?.installmentsNumber > 1 ? 's' : ''}
        </IdsLiDescription>
      </Shimmer>
    </IdsLi>
    <IdsLi>
      <IdsLiText>Dinheiro na conta até</IdsLiText>
      <IdsLiDescription>
        <IdsLiText>
          {proposal?.heroOfferHighlights?.[0]?.infosDescription}
        </IdsLiText>
      </IdsLiDescription>
    </IdsLi>
    <If
      condition={proposal?.paymentValue}
      renderIf={
        <>
          <IdsLi lineOff>
            <IdsLiText>Total a pagar</IdsLiText>
            <Shimmer isVisible={isEditingLoading}>
              <IdsLiDescription>
                R$ {formatters.number.toFloat(proposal?.paymentValue)}
              </IdsLiDescription>{' '}
            </Shimmer>
          </IdsLi>
          <IdsLi className="list__subdescription" lineOff >
            <IdsLiSubText>Juros</IdsLiSubText>
            <IdsLiSubDescription>
              <Shimmer isVisible={isEditingLoading}>
                <IdsLiSubDescription>
                {formatters.number.toPercent(
                  proposal?.interestRateMonthly,
                  2
                )}{' '}
                ao mês
                </IdsLiSubDescription>
              </Shimmer>
            </IdsLiSubDescription>
          </IdsLi>
        </>
      }
    />
  </IdsList>
)

export default FGTSList
