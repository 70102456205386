import { If, DirectionalButton } from '@itau-loans-www/shopping/src/components'
import {
  finishProposalOnCardCallback,
  goToSimulationProposalCallback,
  webAppCallback
} from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import './default-hero-offer-card.scss'

const CardDefaultHeroOffer = (props) => {
  const currentProposal = props?.currentProposal
  const {
    title,
    titleArray,
    subtitle,
    description,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    heroOfferHighlights,
    showInterestRate,
    interestRateMonthly,
    maxLoanValue
  } = currentProposal

  const { number } = formatters

  const callbackButtonExceptions = {
    FGTS: goToSimulationProposalCallback,
    fgts_app: goToSimulationProposalCallback,
    'CONSIG CORR OP / Person': goToSimulationProposalCallback,
    'CONSIG CORR EP / Person': goToSimulationProposalCallback,
    'CONSIG CORR EP': goToSimulationProposalCallback,
    'CONSIG CORR OP': goToSimulationProposalCallback,
    consig_refin_inss_ncor: goToSimulationProposalCallback,
    consig_ncor: goToSimulationProposalCallback,
    consortium: webAppCallback
  }

  const hasEditScreen =
    callbackButtonExceptions[productCode] === goToSimulationProposalCallback

  const callbackButtonFunction =
    callbackButtonExceptions[productCode] || finishProposalOnCardCallback

  const hasMaxLoanValue = 'maxLoanValue' in props.currentProposal

  const hasPreApprovedFlag = 'showPreApprovedValue' in props.currentProposal

  const coldOffer =
    !hasPreApprovedFlag && !(hasMaxLoanValue && maxLoanValue > 0)

  return (
    <div className="hero-offer-card">
      <div className="hero-offer-card__header">
        <If
          condition={title}
          renderIf={
            <p className="hero-offer-card__title hero-offer-card__title--unique">
              {title}
            </p>
          }
          renderElse={
            <span>
              {titleArray?.map((item, index) => (
                <p className="hero-offer-card__title" key={index}>
                  {item}
                </p>
              ))}
            </span>
          }
        />
        <p className="hero-offer-card__description">{description}</p>
      </div>

      <div className="hero-offer-card__main-content">
        <If
          condition={!coldOffer}
          renderIf={
            <div className="hero-offer-card__approved-value">
              <p className="hero-offer-card__approved-value__title">
                Pré aprovado
              </p>
              <p className="hero-offer-card__approved-value__value">
                {number.toCurrency(maxLoanValue)}
              </p>
            </div>
          }
          renderElse={<p className="hero-offer-card__subtitle">{subtitle}</p>}
        />

        <AdditionalData
          className={'--hero-offer-card'}
          list={heroOfferHighlights}
          interestRateMonthly={interestRateMonthly}
          showInterestRate={showInterestRate}
        />
      </div>

      <div className="hero-offer-card__final-content --debts">
        <DirectionalButton
          {...props}
          coldOffer={coldOffer}
          hasEditScreen={hasEditScreen}
          buttonText={buttonText}
          callback={callbackButtonFunction}
          ariaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />
      </div>
    </div>
  )
}

export default CardDefaultHeroOffer
