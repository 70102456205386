import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from '../services'

const usePhone = () => {
  const [isButtonLoading, setButtonLoading] = useState(false)

  const { changeLeadData } = useLead()

  useEffect(() => {
    const getPhone = async () => {
      try {
        const { previous_step } = await services.getPhoneNumber()

        changeLeadData({ previousStep: previous_step })
      } catch (err) {
        const errorPayload = {
          error: err?.message,
          requestURL: err?.request?.responseURL
        }

        DDPageError({
          name: 'usePhone | getPhone',
          payload: errorPayload
        })
      }
    }

    getPhone()

    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-telefone'
    })
  }, [])

  const onSubmit = async ({ phone }) => {
    setButtonLoading(true)

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-telefone',
      detail: 'click-step-basic'
    })

    try {
      const { next_step } = await services.putPhoneNumber({ phone })

      changeLeadData({
        phone
      })
      goTo(next_step)
    } catch (err) {
      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'usePhone | onSubmit',
        payload: errorPayload
      })
    } finally {
      setButtonLoading(false)
    }
  }

  return {
    onSubmit,
    isButtonLoading
  }
}

export default usePhone
