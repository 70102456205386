import { useState, useEffect } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import { occupationDictionary } from './constants'
import services from './services'

const useSelectOccupation = () => {
  const { setIsLoading } = useProposal()
  const { changeLeadData } = useLead()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-fonte-renda' })
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        setGetLoading(true)

        const { previous_step } = await services.getOccupation()
        changeLeadData({
          previousStep: previous_step
        })
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectOccupation | getData',
          payload
        })
      } finally {
        setGetLoading(false)
      }
    }

    getData()
    console.log('occupation mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { occupation } = payload

    const requestBody = {
      occupation
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-fonte-renda',
      detail: 'click-step-fonte-renda',
      customLayer: {
        description: `fonte-renda-${occupationDictionary[occupation]}`
      }
    })

    try {
      const { next_step } = await services.putOccupation(requestBody)
      changeLeadData(requestBody)
      goTo(next_step)
    } catch (error) {
      const payload = {
        error: error?.message,
        requestURL: error?.request?.responseURL
      }

      DDPageError({
        name: 'useSelectOccupation | onSubmit',
        payload
      })
    } finally {
      setSubmitLoading(false)
    }
  }

  return { onSubmit, submitLoading, getLoading }
}

export default useSelectOccupation
