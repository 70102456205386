import { finishProposalOnCardCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import classnames from 'classnames'
import { DirectionalButton } from 'components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
// import { proposal } from './mock'
import './second-credit-card-offer.scss'

const CreditCardOffer = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlightsSecondary,
    productCode,
    buttonText,
    buttonAriaLabel,
    buttonTrackingName,
    imageSecondary,
    hasLongSecondaryHighlights
  } = currentProposal
  // } = proposal

  const finalContentClassnames = classnames(
    'second-credit-card-offer__final-content',
    {
      'second-credit-card-offer__final-content--is-long-highlights':
        hasLongSecondaryHighlights
    }
  )

  return (
    <div className="second-credit-card-offer children-custom">
      <figure className="second-offer-card__img-wrapper">
        <img
          src={imageSecondary}
          alt={""}
          className="img second-offer-card__card-img"
          width="132"
          height="117"
        />
      </figure>
      <div className="second-credit-card-offer__content">
        <p className="second-credit-card-offer__title">{title}</p>
        <div className="second-credit-card-offer__group">
          <AdditionalData list={highlightsSecondary} />
          <div className={finalContentClassnames}>
            <DirectionalButton
              {...props}
              id={`secondary-offer-${productCode}-btn`}
              buttonText={buttonText}
              ariaLabel={buttonAriaLabel}
              buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
              callback={finishProposalOnCardCallback}
              dataTestid="secondary-offer-credit-card-offer-btn"
              className="directional-button__second-offer-credit-card"
            />

            <Disclaimer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCardOffer
