import { useForm, useController } from 'react-hook-form'

import {
  IdsRadioButton,
  IdsList,
  IdsLi,
  IdsLiText,
  IdsLiSubText,
  IdsLiDescription,
  IdsLiSubDescription
} from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { Navigation, If } from 'components'
import ProposalLayout from 'layouts/Proposal'
import { formatters } from 'utils'

import './edit-installments.scss'
import { useEditInstallments } from './useEditInstallments'

const Installments = ({ pageContext }) => {
  const { product, setProduct } = useProposal()
  const proposal = product?.proposal

  const { onSubmit } = useEditInstallments({ proposal, setProduct })

  const { additionalData } = proposal ?? {}
  const { paymentOptions = [] } = additionalData ?? {}

  const defaultValue = String(
    paymentOptions.findIndex(({ selected }) => selected === true)
  )

  const initialValues = {
    payment: defaultValue
  }
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { field } = useController({
    name: 'payment',
    control
  })

  return (
    <ProposalLayout pageContext={pageContext}>
      <div className="installments">
        <h2 className="installments__title">Como você quer parcelar</h2>
        <h2 className="installments__subtitle">
          Escolha quanto você precisa e como quer pagar
        </h2>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="installments__list">
            {paymentOptions.map(({ label, value, selected }, idx) => {
              return (
                <IdsRadioButton
                  {...field}
                  key={idx}
                  value={value}
                  name="payment"
                  onChange={(e) => field.onChange(e)}
                  defaultChecked={selected}
                  aria-labelledby={`installments-label-${idx}`}
                >
                  <label id={`installments-label-${idx}`}>{label}</label>
                </IdsRadioButton>
              )
            })}
          </div>
          <If
            condition={proposal?.paymentValue}
            renderIf={
              <div className="installments__final">
                <IdsList>
                  <IdsLi className="installments__final--line-top">
                    <IdsLiText>Total a pagar</IdsLiText>
                    <IdsLiDescription>
                      R$ {formatters.number.toFloat(proposal?.paymentValue)}
                    </IdsLiDescription>
                  </IdsLi>
                  <IdsLi className="list__subdescription">
                    <IdsLiSubText>Juros</IdsLiSubText>
                    <IdsLiSubDescription>
                      {formatters.number.toPercent(
                        proposal?.interestRateMonthly,
                        2
                      )}{' '}
                      ao mês
                    </IdsLiSubDescription>
                  </IdsLi>
                </IdsList>
              </div>
            }
          />

          <Navigation
            submit
            nextStep
            isNextButtonDisabled={!field.value}
            nextButtonText="Confirmar"
            nextButtonId="installments-btn"
          />
        </form>
      </div>
    </ProposalLayout>
  )
}

export default Installments
