import {
  IdsList,
  IdsLi,
  IdsLiText,
  IdsLiSubText,
  IdsLiDescription,
  IdsLiSubDescription
} from '@ids/react'
import { Shimmer, If } from 'components'
import { formatters } from 'utils'

import useDefaultList from './hooks'

const DefaultList = ({ isEditing, isEditingLoading, proposal }) => {
  const { handleEditButton } = useDefaultList({ proposal })

  return (
    <IdsList>
      <IdsLi
        disabled={isEditing || isEditingLoading}
        tagName="button"
        onClick={handleEditButton}
      >
        <IdsLiText>
          Parcelas
          <Shimmer isVisible={isEditingLoading}>
            <IdsLiSubText>
              {proposal?.installmentsNumber}x de R${' '}
              {formatters.number.toFloat(proposal?.installmentsValue)}
            </IdsLiSubText>
          </Shimmer>
        </IdsLiText>

        <IdsLiDescription>Editar</IdsLiDescription>
      </IdsLi>
      <IdsLi>
        <IdsLiText>Dinheiro na conta até</IdsLiText>
        <IdsLiDescription>
          {proposal?.heroOfferHighlights?.[0]?.infosDescription}
        </IdsLiDescription>
      </IdsLi>
      <If
        condition={proposal?.paymentValue}
        renderIf={
          <>
            <IdsLi lineOff>
              <IdsLiText>Total a pagar</IdsLiText>
              <IdsLiDescription>
                <Shimmer isVisible={isEditingLoading}>
                  <IdsLiDescription>
                    R$ {formatters.number.toFloat(proposal?.paymentValue)}
                  </IdsLiDescription>{' '}
                </Shimmer>
              </IdsLiDescription>
            </IdsLi>
            <IdsLi className="list__subdescription" lineOff>
              <IdsLiSubText>Juros</IdsLiSubText>
              <IdsLiSubDescription>
                <Shimmer isVisible={isEditingLoading}>
                  <IdsLiSubDescription>
                    {formatters.number.toPercent(
                      proposal?.interestRateMonthly,
                      2
                    )}{' '}
                    ao mês
                  </IdsLiSubDescription>
                </Shimmer>
              </IdsLiSubDescription>
            </IdsLi>
          </>
        }
      />
    </IdsList>
  )
}

export default DefaultList
